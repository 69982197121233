<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { keyBy } from 'lodash-es';
import QuickFilters from '~/common/components/organisms/hawk-filters/hawk-quick-filters.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';

const props = defineProps({
  name: {
    type: String,
    default: 'rules',
  },
  rules: {
    default: () => [],
  },
  options: {
    type: Object,
    default: () => {},
  },
  fields: {
    type: Array,
    default: () => [],
  },
  custom_date_filter_options: {
    type: Object,
    default: null,
  },
  is_required: {
    type: Boolean,
    default: true,
  },
  is_default_field_added: {
    type: Boolean,
    default: false,
  },
  is_generic: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['invalid', 'updateRules', 'getFilterPayload']);

const FormFieldTree = defineAsyncComponent(() => import('~/forms/molecules/form-field-tree.vue'));

const form_template_detail_store = useFormTemplateDetailStore();

const rules = ref(props.rules || []);
const force_update = ref(0);
const fields_options = computed(() => {
  return props.fields.reduce((acc, field) => {
    field.uid = field.nodeKey ? snakeToCamel(field.uid) : field.uid;
    if ((props.is_generic && keyBy(props.fields, 'uid')[field.uid]) || form_template_detail_store.field_filters_map[field.uid])
      acc.push({ ...field, disabled: rules.value.map(val => val.field).includes(field.uid) });
    return acc;
  }, []);
});
const get_available_field_options = computed(() => {
  return fields_options.value.filter(field => !field.disabled);
});
const field_by_uid = computed(() => {
  return fields_options.value.reduce((acc, curr) => {
    acc[curr.uid] = curr;
    return acc;
  }, {});
});
const invalid_rules = computed(() => {
  return rules.value.filter((rule, i) => {
    const field_details = getFieldDetails(i);
    if (['number', 'attachments'].includes(field_details?.type) && ['isBetween', 'isNotBetween', 'countIsBetween', 'countIsBetween'].includes(rules.value[i].operator)) {
      if (rule.value) {
        if ((rule.value[0] === null || rule.value[1] === null))
          return true;
        else
          return +rule.value[0] >= +rule.value[1];
      }
      return true;
    }
    return rule.value === null;
  }).length;
});

function snakeToCamel(str) {
  return str.replace(/_([a-z])/g, (match, group1) => {
    return group1.toUpperCase();
  });
}

function getFieldDetails(i) {
  const field_uid = rules.value[i]?.field;
  if (props.is_generic)
    return field_by_uid.value[field_uid];
  return form_template_detail_store.field_filters_map[field_uid];
}
function get_filters_data() {
  return rules.value.map((rule, i) => {
    const field = field_by_uid.value[rule.field];
    return {
      ...rule,
      name: field.name,
      data: field.column?.data,
      type: getFieldDetails(i).type,
      fact: rule.field,
      ...(field.path && { path: field.path }),
    };
  });
}

function setDefaultOperator(i, value) {
  if (rules.value[i]) {
    if (value)
      rules.value[i].field = value;
    setDefaultValue(i, getFieldDetails(i).operators[0]);
    force_update.value = force_update.value + 1;
  }
}
function setDefaultValue(i, value = null) {
  if (!rules.value[i])
    return;
  if (value) {
    rules.value[i].operator = value;
    rules.value[i].value = null;
  }

  const field_details = getFieldDetails(i);

  if (['number', 'attachments'].includes(field_details.data_type) && ['isBetween', 'isNotBetween', 'countIsBetween', 'countIsBetween'].includes(rules.value[i].operator))
    rules.value[i].value = [null, null];
  if (['blankNotBlank', 'BlankNotBlank', 'boolean'].includes(rules.value[i].operator))
    rules.value[i].value = true;
  force_update.value = force_update.value + 1;
}

function addNewField() {
  rules.value.push({ field: get_available_field_options.value[0]?.uid });
  setDefaultOperator(rules.value.length - 1);
  force_update.value = force_update.value + 1;
}

function init() {
  rules.value = [...props.rules];
  if (!rules.value.length && get_available_field_options.value.length && props.is_default_field_added)
    addNewField();
  force_update.value = force_update.value + 1;
  emit('getFilterPayload', {
    filters: get_filters_data,
  });
}

init();

watch(invalid_rules, (val) => {
  emit('invalid', val);
});

watch(force_update, () => {
  emit('updateRules', rules.value);
}, { immediate: true });
</script>

<template>
  <div>
    <ListElement
      :name="name"
      v-bind="options"
      :rules="is_required ? 'required' : ''"
      :controls="{ add: false, remove: false, sort: false }"
    >
      <template #default="{ index }">
        <ObjectElement
          :name="index"
        >
          <div class="col-span-12 mb-4">
            <div class="flex w-full gap-2">
              <div class="w-full grid gap-2">
                <div class="flex w-full gap-2">
                  <SelectElement
                    v-if="is_generic"
                    class="w-full"
                    name="field"
                    value-prop="uid"
                    label-prop="name"
                    :search="true"
                    :native="false"
                    :can-clear="false"
                    :hide-selected="true"
                    :can-deselect="false"
                    :items="fields_options"
                    @select="setDefaultOperator(index, $event)"
                  />
                  <FormFieldTree
                    v-else
                    class="w-full"
                    :data="fields_options"
                    :tree_config="{
                      options: {
                        name: 'field',
                        search: true,
                        object: false,
                        native: false,
                        canClear: false,
                        hideSelected: true,
                        canDeselect: false,
                        removeClasses: {
                          SelectElement: {
                            select: {
                              optionSelectedDisabled: 'form-color-on-primary form-bg-primary',
                            },
                          },
                        },
                      },
                      select_type: 'LEAF_PRIORITY',
                      select_view: 'SELECT',
                      show_expanded_search: true,
                    }"
                    @on-select="setDefaultOperator(index, $event?.[0]?.uid)"
                  />
                </div>
                <QuickFilters
                  :key="`${rules[index].field}_${rules[index].operator}`"
                  name=""
                  :uid="rules[index].field"
                  :quick_filters="false"
                  :operators="getFieldDetails(index)?.operators"
                  :data_type="getFieldDetails(index)?.data_type"
                  :option_type="getFieldDetails(index)?.option_type"
                  :options="getFieldDetails(index)?.options"
                  :default_value="rules[index]"
                  :field="field_by_uid[rules[index].field]"
                  :custom_date_filter_options="custom_date_filter_options"
                  @update-operator="($event) => {
                    setDefaultValue(index, $event.operator)
                  }"
                  @update-form="($event) => {
                    rules[index].operator = $event.operator;
                    rules[index].value = $event.value;
                    rules[index].operator_option = $event.operator_option || null;
                    force_update++;
                  }"
                />
              </div>
              <div>
                <HawkButton
                  icon type="text"
                  class="flex-shrink-0" @click="() => {
                    rules.splice(index, 1);
                    emit('updateRules', rules);
                  }"
                >
                  <IconHawkTrashOne class="text-gray-600 w-4.5 h-4.5" />
                </HawkButton>
              </div>
            </div>
          </div>
        </ObjectElement>
      </template>
    </ListElement>
    <HawkButton type="link" class="my-4" :disabled="!get_available_field_options.length || !!invalid_rules" @click="addNewField()">
      <IconHawkPlus
        class="text-primary-700 w-5 h-4.75 mx-1"
      />
      <div class="font-semibold">
        {{ $t('Add condition') }}
      </div>
    </HawkButton>
  </div>
</template>
