import { $date } from '~/common/utils/date.util';
import { createTableContainer, createTables } from '~/dashboard/print/charts/table';

const form_status_map = {
  0: 'Open',
  1: 'Draft',
  2: 'Submitted',
};

function getFormListItemStatus(item) {
  const status = item.status?.name || '';

  return status[0].toUpperCase() + status.slice(1);
}

function createFormsList(chart, index) {
  const columns = [
    [
      {
        id: 'name',
        header: 'Name',
      },
      {
        id: 'assignees',
        header: 'Assignees',
      },
      {
        id: 'due_date',
        header: 'Due Date',
      },
      {
        id: 'status',
        header: 'Status',
      },
    ],
  ];

  let dataset = [];
  dataset = chart.dataSource.dataset.map((form) => {
    const assignees = form.assignees?.length;
    const due_date = form.due_date
      ? $date(form.due_date, 'DD MMMM YYYY')
      : '-';
    return {
      name: form.name,
      assignees: assignees === 1 ? '1 Member' : `${assignees} Members`,
      due_date,
      status: getFormListItemStatus(form),
    };
  });

  const tables = createTables(columns, dataset, 'list');

  const container = createTableContainer(
    tables,
    chart.chart_name,
    chart.dataSource.total - 10,
    chart,
  );

  return container;
};

export { createFormsList };
