import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { isEmpty, isNil } from 'lodash-es';
import { computed, inject, markRaw } from 'vue';
import { useRoute } from 'vue-router';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { services } from '~/common/installers/services';
import { $toast, getPageNameByRouteName } from '~/common/utils/common.utils';

dayjs.extend(isBetween);

export function useFieldSectionVisibility(form_detail_store, is_submitted) {
  const route = useRoute();
  const is_preview_mode = computed(() => {
    if (route?.query?.form) {
      const query = JSON.parse(atob(route.query.form));
      return query?.is_preview;
    }
    else {
      return false;
    }
  });
  const visibility_class_map = {
    view: '',
    hide: 'hidden',
    edit: 'pointer-events-auto',
  };
  const visibility_object = {
    get_section_visibility(section) {
      if (is_preview_mode.value)
        return section?.status === 'active' ? 'pointer-events-auto' : 'hidden';

      const section_visibility_map = form_detail_store.form_detail.visibility.sections;
      return visibility_class_map[section_visibility_map[section.uid]];
    },
    get_field_visibility(section, field) {
      if (is_preview_mode.value)
        return field?.status === 'active' ? 'pointer-events-auto' : 'hidden';

      const field_visibility_map = form_detail_store.form_detail.visibility.fields;
      if (field.properties.type === 'info')
        return visibility_class_map[field_visibility_map[field.uid]] === 'hidden' ? 'hidden' : 'pointer-event-auto';
      else if (field.properties.type === 'signature')
        return visibility_class_map[field_visibility_map[field.uid]] === '' ? 'pointer-events-none' : visibility_class_map[field_visibility_map[field.uid]];
      else
        return visibility_class_map[field_visibility_map[field.uid]];
    },
  };
  return visibility_object;
}

export function useFormatSavePayload(form_detail_store) {
  const {
    auth_store,
  } = useCommonImports();

  function removeEmptyRows() {
    const table_sections = form_detail_store?.sections.filter(section => section.type === 'tablev2' && section.status === 'active');
    const table_section_fields = table_sections.map(section => section.fields.filter(field => field.status === 'active'));

    table_section_fields.forEach((section_fields) => {
      const table_row_length = section_fields[0].properties.value?.length;

      for (let row_index = table_row_length - 1; row_index >= 0; row_index--) {
        const section_field_values = section_fields.map(field => ({
          field_uid: field.uid,
          field_type: (typeof field?.config?.type === 'string' ? field.config.type : null) || field?.properties?.type || field?.type,
          field_value: field.properties?.value?.[row_index],
        }));

        const has_no_value = section_field_values.every((value) => {
          const { field_value, field_type } = value;

          if (['attachment', 'signature'].includes(field_type)) {
            return field_value === '' || isNil(field_value?.[0]?.created_at) || isNil(field_value?.meta?.created_at);
          }
          if (Array.isArray(field_value) || typeof field_value === 'object')
            return isEmpty(field_value);
          return isNil(field_value) || field_value === '';
        });

        if (has_no_value) {
          section_fields.forEach((field) => {
            field.properties?.value?.pop();
            field.properties?._value?.pop();
          });
        }
        else {
          break;
        }
      }
    });
    return table_section_fields.flat();
  }

  function filterDeletedValues(table_fields_data) {
    table_fields_data.forEach((field) => {
      const field_type = (typeof field.config.type === 'string' ? field.config.type : null) || field.properties.type || field.type;

      if (field_type === 'radio' && field.properties.value) {
        const deleted_options = field.config.filter(config => config.deleted).map(config => config.uid);
        field.properties.value.forEach((option_uid, index) => {
          if (deleted_options.includes(option_uid))
            field.properties.value[index] = null;
        });
      }
      if (field_type === 'dropdown' && field.properties.value) {
        const deleted_options = field.config.filter(config => config.deleted).map(config => config.uid);
        field.properties.value.forEach((option_uid, index) => {
          if (deleted_options.includes(option_uid))
            field.properties.value[index] = null;
        });
      }
      if (field_type === 'checkbox' && field.properties.value) {
        const deleted_options = field.config.filter(config => config.deleted).map(config => config.uid);
        field.properties.value.forEach((option_uids, index) => {
          field.properties.value[index] = option_uids?.filter(option_uid => !deleted_options.includes(option_uid));
        });
      }
    });
  }

  function getTableSectionData(table_fields_array) {
    const fields_data = {};
    table_fields_array.forEach((field_config_items) => {
      const values = field_config_items.properties.value || [];
      fields_data[field_config_items.uid] = values;
    });
    return fields_data;
  }

  function getFormFieldsData(field_value_map) {
    const fields_array = form_detail_store?.sections.filter(section => section.type !== 'tablev2').map(section => section.fields.filter(field => field.status === 'active' && field.properties.type !== 'info').map(field => field)).flat();
    let fields_data = {};
    fields_array.filter(field_config_items => field_value_map[field_config_items?.uid] != null).forEach(field_config_items => fields_data[field_config_items?.uid] = field_value_map[field_config_items?.uid]);

    let table_fields_array = form_detail_store?.sections.filter(section => section.type === 'tablev2').map(section => section.fields.filter(field => field.status === 'active').map(field => field)).flat();
    if (auth_store.check_split('forms_hands_on_table')) {
      table_fields_array = removeEmptyRows();
      filterDeletedValues(table_fields_array);
    }
    const table_field_data = getTableSectionData(table_fields_array);
    fields_data = { ...fields_data, ...table_field_data };
    return fields_data;
  }

  return getFormFieldsData;
}

export function useExportPDF() {
  const $track_event = inject('$track_event');
  const route = useRoute();

  async function formsPDFExportHandler(forms, template_uid, exportFailed = () => {}, forms_store = null) {
    const $services = markRaw(services);

    try {
      const { data: resourceURL } = await $services.forms.get_custom_report({
        body: {
          ...(template_uid ? { templateUid: template_uid } : {}),
          formUids: forms.map(form => form.uid),
          assetUid: route?.params?.asset_id,
        },
      });

      if (typeof (resourceURL) === 'string') {
        const link = document.createElement('a');
        link.href = resourceURL;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      if (forms_store) {
        forms_store.forms_track_events('Exported', forms[0].uid, { output_format: 'PDF', count: forms.length, with_summary: false });
      }
      else {
        $track_event('Exported', {
          count: forms.length,
          output_format: 'PDF',
          view: getPageNameByRouteName(route.name),
          with_summary: false,
          integration: forms[0]?.properties?.integration?.type || null,
        });
      }
    }
    catch (err) {
      logger.error(err);
      $toast({
        text: 'Couldn\'t download PDF. Please try again',
        type: 'error',
      });
      exportFailed();
    }
  }

  return {
    formsPDFExportHandler,
  };
}
