export async function exportReportAsXlsx({ headers, data, nestedHeaders }, file_name) {
  try {
    const [{ Workbook }, { saveAs }] = await Promise.all([
      import('exceljs'),
      import('file-saver'),
    ]);

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    const headerStyle = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'd9d9d9' },
      },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
      alignment: {
        horizontal: 'center',
        vertical: 'middle',
      },
      font: {
        bold: true,
      },
    };

    const dataCellStyle = {
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
      alignment: {
        horizontal: 'left',
        vertical: 'middle',
      },
    };

    const headerRows = nestedHeaders ? headers : [headers];
    let currentRow = 1;

    const maxColWidth = Array.from({ length: Math.max(...headerRows.map(row =>
      row.reduce((sum, header) => sum + (header.colspan || 1), 0),
    )) }).fill(15);

    data.forEach((row) => {
      row.forEach((cell, idx) => {
        const cellLength = String(cell || '').length;
        if (cellLength > maxColWidth[idx]) {
          maxColWidth[idx] = cellLength;
        }
      });
    });

    headerRows.forEach((headerRow) => {
      let currentCol = 1;
      headerRow.forEach((header) => {
        const cell = worksheet.getCell(currentRow, currentCol);
        cell.value = header.label;

        const colspan = header.colspan || 1;
        if (colspan > 1) {
          worksheet.mergeCells(currentRow, currentCol, currentRow, currentCol + colspan - 1);
        }

        Object.assign(cell, headerStyle);

        currentCol += colspan;
      });
      currentRow++;
    });

    worksheet.addRows(data);

    worksheet.columns = maxColWidth.map((width, index) => ({
      key: String(index + 1),
      width: Math.min(Math.max(width, 15), 50),
    }));

    const dataEndCol = maxColWidth.length;
    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      if (rowNumber >= currentRow) {
        for (let col = 1; col <= dataEndCol; col++) {
          const cell = row.getCell(col);
          Object.assign(cell, dataCellStyle);
        }
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${file_name}.xlsx`);
  }
  catch (error) {
    logger.error('Failed to export', error);
  }
}
