<script setup>
import { orderBy } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useDashboardInventoryStore } from '~/dashboard/store/dashboard-inventory.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import FamTabFormRules from '~/forms-as-module/components/fam-tabs-list/fam-tab-form-rules.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const emit = defineEmits(['update']);
const $t = inject('$t');

const dashboard_inventory_store = useDashboardInventoryStore();
const dashboard_store = useDashboardStore();
const inventory_store = useInventoryStore();

const { inventory_widget_type } = storeToRefs(dashboard_inventory_store);
const { widget_configuration } = storeToRefs(dashboard_store);

const workflows = computed(() => orderBy(inventory_store.workflows, ['order_index'], ['asc']).map((workflow) => {
  return {
    name: workflow.name,
    uid: workflow.uid,
  };
}));

const supported_custom_field_types = [
  'text',
  'number',
  'dropdown',
  'checkboxes',
  'date',
  'members',
  'member',
  'email',
];

const filtered_custom_fields = computed(() => {
  return inventory_store.custom_fields
    .filter(field => field.active && supported_custom_field_types.includes(field.type))
    .map((field) => {
      return {
        ...field,
        name: field.name,
        uid: `custom_field.${field.uid}`,
      };
    });
});

const group_by_filtered_options = computed(() => {
  return [
    { name: 'Date', uid: 'date' },
    { name: 'Warehouse', uid: 'warehouse' },
    { name: 'Asset', uid: 'asset' },
    ...filtered_custom_fields.value,
  ];
});

const filter_fields = computed(() => [
  {
    name: $t('Type'),
    is_static: true,
    data_type: 'single_value_select',
    operators: ['equalsExactly'],
    option_type: null,
    options: [...workflows.value],
    uid: 'workflow',
  },
  ...(inventory_widget_type.value === 'to_status'
    ? [{
        uid: 'date',
        name: $t('Date'),
        data_type: 'date',
        is_static: true,
        option_type: null,
        operators: ['isEqualTo'],
        options: [],
        type: 'date',
      }]
    : []),
  ...(['transaction', 'adjustment'].includes(inventory_widget_type.value)
    ? [{
        uid: 'dates',
        name: $t('Date'),
        data_type: 'date',
        is_static: true,
        option_type: null,
        operators: ['between'],
        options: [],
        type: 'date',
      }]
    : []),
  ...(inventory_widget_type.value === 'adjustment'
    ? [{
        uid: 'group_by',
        name: $t('Group by'),
        is_static: true,
        data_type: 'single_value_select',
        operators: ['equalsExactly'],
        option_type: null,
        options: group_by_filtered_options.value,
      }]
    : []),
]);

const init = ref(true);
const form_data = ref({ filters: [] });

const rules_state = reactive({
  is_invalid: false,
  get_filters_payload: null,
});

function loadInventoryFilters() {
  const filter_rules = widget_configuration.value?.filter_rules || [];

  if (filter_rules?.length)
    form_data.value.filters = filter_rules;

  init.value = false;
}

function updateRules(rules) {
  if (rules)
    form_data.value.filters = rules;

  if (!rules_state.is_invalid) {
    emit('update', [...rules]);
  }
}

onMounted(() => {
  loadInventoryFilters();
});
</script>

<template>
  <div class="text-sm text-blue-600 font-medium cursor-pointer">
    {{ $t('Advanced filters') }}
  </div>
  <Vueform
    v-if="!init"
    v-model="form_data"
    sync
    size="sm"
  >
    <div class="col-span-12 mt-4">
      <FamTabFormRules
        name="filters"
        :rules="form_data.filters"
        :fields="filter_fields"
        :is_required="false"
        :is_generic="true"
        @update-rules="updateRules"
        @invalid="($event) => (rules_state.is_invalid = $event)"
        @get-filter-payload="rules_state.get_filters_payload = $event"
      />
    </div>
  </Vueform>
</template>
