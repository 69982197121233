<script setup>
import { includes, isEqual, sortBy, uniqBy } from 'lodash-es';

import { watch } from 'vue';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import { convertTableUnitsData } from '~/common/utils/common.utils.js';

import { useDashboardTerraStore } from '~/dashboard/store/dashboard-terra.store.js';

import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  // eslint-disable-next-line vue/prop-name-casing
  content_height: {
    type: Number,
  },
  activeSelectedRange: {
    type: Array,
    default: () => [],
  },
});

const $t = inject('$t');

const $services = inject('$services');

const dashboard_selected_range = inject('dashboardSelectedRange');

const dashboard_store = useDashboardStore();
const dashboard_terra_store = useDashboardTerraStore();

const HEADER_KEY_MAP = {
  'total': $t('Total (scope)'),
  'actual': $t('Actual'),
  'remaining': $t('Remaining'),
  '%_actual': $t('% Actual'),
  '%_remaining': $t('% Remaining'),
  'daily_progress': $t('Today'),
  'this_week': $t('This week'),
  'this_month': $t('This month'),
  'this_year': $t('This year'),
  'dashboard_active_selected_range': $t('Selected range'),
};

const loading = ref(false);
const activities = ref([]);
const columns = ref([]);
const payload = ref(null);
const forceUpdate = ref(0);
const no_data = ref(false);
const prevent_watcher = ref(false);

async function getReports() {
  loading.value = true;
  payload.value = dashboard_terra_store.parse_terra_form_to_server_format(props.data.data);
  try {
    const { data } = await $services.terra_view_service.get_activities_table({ body: payload.value });

    if (Object.keys(data?.data)?.length) {
      no_data.value = false;
      if (props.data.data.transpose)
        generateTransposeActivities(data.data);
      else
        generateActivities(data.data);
      loading.value = false;
    }
    else {
      no_data.value = true;
      columns.value = [];
      activities.value = [];
      loading.value = false;
    }

    forceUpdate.value++;
  }
  catch (err) {
    loading.value = false;
    logger.log({ err });
  }
}

const columns_widths_map = computed(() => props.data.data.columns_widths || {});

const field_name_map = computed(() => {
  const res = {};
  props.data.data?.workprogress_fields?.forEach((f) => {
    res[f.column] = f.name;
  });

  return res;
});

function getFieldName(key, value) {
  if (key === 'name')
    return 'Activity';

  const field_name = field_name_map.value[key] || 'Untitled';

  if (value.field_units)
    return `${field_name} (${value.field_units})`;

  return field_name;
}

function getTransposeFieldName(data) {
  const field_name = data?.name || 'Untitled';
  if (data?.field_units)
    return `${field_name} (${data.field_units})`;
  else if (data?.units)
    return `${field_name} (${data.units})`;

  return field_name;
}

function getActivityCellValue(data, key) {
  if (!props.data.data.prettify_units) {
    return data.values[key];
  }
  else
    if (includes(key, '%')) {
      let val = data.values[key];
      if (Math.ceil(val) !== val)
        val = val.toFixed(2);
      return `${val}%`;
    }
  return convertTableUnitsData(data.values[key], data.field_units);
}

function generateActivities(data) {
  const activity_array = [];
  const column_array = [];

  Object.values(data).forEach((value) => {
    const current_item = {};
    for (const [nestedKey, nestedValue] of Object.entries(value)) {
      if (nestedKey !== 'uid') {
        column_array.push({
          accessorKey: nestedValue?.values && Object.keys(nestedValue.values).length === 1 ? `${Object.keys(nestedValue?.values)[0]}-${nestedKey}` : nestedKey,
          header: getFieldName(nestedKey, nestedValue),
          ...(nestedKey === 'name' ? { size: columns_widths_map.value.name || 400 } : {}),
          id: nestedKey,
          ...(nestedValue?.values && Object.keys(nestedValue.values).length > 1
            ? {
                columns: Object.keys(nestedValue.values).map((child) => {
                  const col_id = `${child}-${nestedKey}`;
                  return {
                    accessorKey: col_id,
                    id: col_id,
                    header: child === 'id' ? 'Activity id' : HEADER_KEY_MAP[child],
                    size: columns_widths_map.value?.[col_id] || '150',
                  };
                },
                ),
              }
            : {
                size: columns_widths_map.value?.[nestedKey] || '150',
              }),

        });
      }
      if (nestedValue.values) {
        Object.keys(nestedValue.values).forEach((nestedItem) => {
          current_item[`${nestedItem}-${nestedKey}`] = getActivityCellValue(nestedValue, nestedItem);
        });
      }

      else {
        current_item[nestedKey] = props.data.data.prettify_units ? convertTableUnitsData(nestedValue, nestedValue.field_units) : nestedValue;
      }
    }
    activity_array.push(current_item);
  });

  activities.value = sortBy(activity_array, ['name']);
  columns.value = uniqBy(column_array, c => c.id);
}
function generateTransposeActivities(data) {
  const column_array = [{
    accessorKey: 'name',
    id: 'name',
    header: 'Field',
    size: columns_widths_map.value.name || 400,
  }];
  Object.values(data).forEach((value) => {
    const current_column = {
      accessorKey: value.name,
      header: value === 'name' ? 'Activity' : value.name,
      id: value.name,
      columns: [],
    };

    for (const [_nestedKey, nestedValue] of Object.entries(value)) {
      // Generating sub columns

      // change header names to correct name

      if (nestedValue.values) {
        current_column.columns = Object.keys(nestedValue.values).map((nestedCol) => {
          const col_id = `${nestedCol}-${value.uid}`;
          return {
            accessorKey: col_id,
            id: col_id,
            header: nestedCol === 'id' ? 'Activity id' : HEADER_KEY_MAP[nestedCol],
            size: columns_widths_map.value[col_id] || 150,
          };
        });
      }

      column_array.push(current_column);
    }
  });

  const fields = Object.keys(data).reduce((acc, key) => {
    Object.keys(data[key]).forEach((keyItem) => {
      if (!acc.present[keyItem] && typeof data[key][keyItem] === 'object') {
        acc.present = { ...acc.present, [keyItem]: keyItem };
        return acc.list.push(keyItem);
      }
    });
    return acc;
  }, {
    list: [],
    present: {},
  });

  const activity_array_better = fields.list.reduce((acc, fieldKey) => {
    const fieldData = {};
    Object.keys(data).forEach((dataKey) => {
      const dataItem = data[dataKey][fieldKey];
      fieldData.name = getTransposeFieldName(dataItem);
      Object.keys(dataItem?.values ?? {}).forEach((valueKey) => {
        fieldData[`${valueKey}-${dataKey}`] = getActivityCellValue(dataItem, valueKey);
      });
    });
    acc.push(fieldData);
    return acc;
  }, []);

  activities.value = activity_array_better;
  const name_column = column_array[0];
  const activity_columns = uniqBy(column_array, c => c.id).slice(1) || [];
  columns.value = [
    name_column,
    ...sortBy(activity_columns, ['header']),
  ];
}

const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'table',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dashboard_selected_range,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: columns.value,
      activities: activities.value,
      dataset: activities.value,
      is_transpose: props.data.data.transpose === true,
      dashboard_index: props.data.i,
      is_new_pivot_chart: props.data.data.chart === 'workflow_pivot_table',
    },
  });
  dashboard_store.update_new_print_map((props.data?.data?.name || 'untitled'), {
    widget_type: 'table',
    type: props.data?.data?.type,
    data: parseNewPrintData(activities.value),
  });
}

function parseNewPrintData(data) {
  const accessor_keys = [];
  columns.value.forEach((c) => {
    if (c.columns) {
      c.columns.forEach((cc) => {
        accessor_keys.push(cc.accessorKey);
      });
    }
    else {
      accessor_keys.push(c.accessorKey);
    }
  });
  const parsed_data = [];
  data.forEach((d) => {
    parsed_data.push(accessor_keys.map(k => d[k]));
  });
  return parsed_data;
}

function columnResized(_resized_column, columns_widths) {
  // prevents the table from rerendering
  prevent_watcher.value = true;
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_widths,
  );
}

watch(() => props.data.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    if (prevent_watcher.value) {
      prevent_watcher.value = false;
      return;
    }
    await getReports();
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true }, { deep: true });

watch(() => props.activeSelectedRange, async (new_val, old_val) => {
  if (!isEqual(new_val, old_val) && (props?.id !== 'preview')) {
    await getReports();
    updatePrintMap();
  }
});
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <div v-if="no_data" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
      {{ $t('No data present') }}
    </div>
    <hawk-loader v-if="loading" />
    <a v-else-if="activities?.length">
      <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
        <HawkTable
          :key="forceUpdate"
          :data="activities"
          :columns="columns"
          additional_table_classes=""
          additional_row_classes="even:bg-gray-50"
          :container_class="`h-[${content_height || height}px]`"
          :disable_resize="!dashboard_store.is_editing_dashboard"
          freeze_column_id="name"
          :show_menu_header="false"
          :show_column_borders="true"
          is_gapless
          :striped="true"
          cell_height="30px"
          @column-resized="columnResized"
        />
      </div>
    </a>
  </div>
</template>
